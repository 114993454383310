// <!-- API -->
import { ref, computed, inject } from 'vue';
import {
    createNote,
    updateNoteById,
    deleteNoteById,
} from '@/api/v1/accounts/notes';

// <!-- SYMBOLS -->
import { Resource } from '@/symbols';

// <!-- COMPONENTS -->
import NoteManagerTableIcons from '~NoteManager/components/cells/NoteManagerTableIcons.vue';

// <!-- UTILITIES -->
import clone from 'just-clone';
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';
import { formatISO } from 'date-fns';
import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';
import { Enum } from '@/utils/enums';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useNoteIndex } from '@/hooks/cache/useNoteIndex';
import { useModalToggle } from '@/hooks/modals';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {Router.Router} Router */

/** @typedef {import('@/models/v1/notes/Note').NoteResource} NoteResource */
/** @typedef {import('@/models/v1/notes/NoteAuthor').NoteAuthor} NoteAuthor */
/** @typedef {import('@/models/v1/notes/NoteLocation').NoteLocation} NoteLocation */
/** @typedef {import('@/models/v1/notes/NoteLocationHierarchy').NoteLocationHierarchy} NoteLocationHierarchy */
/** @typedef {globalThis.Account.Model} AccountResource */

/** @template [T=any] @typedef {{ -readonly [P in keyof T]: T[P] }} Writeable */

/** @typedef {Partial<Omit<NoteResource, 'author' | 'parseModel' | 'entries' | 'path'>> & { author?: String }} INoteResource */
/** @typedef {{ id: null } & Required<Pick<INoteResource, 'title' | 'content' | 'dateStart'>> & Omit<INoteResource, 'id'>} INoteTemplate */
/** @typedef {Required<Pick<INoteResource, 'id'>> & INoteResource} INoteTarget */

/** @typedef {import('@/api/v1/accounts/notes').IResponseResult} IResponseResult */

/** @typedef {import('@/api/v1/accounts/notes').IEditNoteTarget} IEditNoteTarget */
/** @typedef {import('@/api/v1/accounts/notes').IDeleteNoteTarget} IDeleteNoteTarget */

/** @typedef {import('@/api/v1/accounts/notes').ICreateNoteRequest} ICreateNoteRequest */
/** @typedef {import('@/api/v1/accounts/notes').IEditNoteRequest} IEditNoteRequest */
/** @typedef {import('@/api/v1/accounts/notes').IDeleteNoteRequest} IDeleteNoteRequest */

// <!-- COMPOSABLE -->
/**
 * Provides access to all composable submodules.
 */
class NoteManager {
    /**
     * Instantiate a new NoteManager composable.
     * @param {Object} [props] Props to pass to the NoteManager.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router} [props.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [props.grid] AgGrid composable.
     * @param {ReturnType<useNoteIndex>} [props.notes] Note index composable.
     */
    constructor(props = {}) {
        // Deconstruct parameters.
        const { store, router, alerts, grid, notes } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();

        /** @type {ReturnType<useNoteIndex>} */
        this.notes = /** @type {any} */ (
            inject(Resource.notes, () => notes ?? useNoteIndex())
        );

        /** @type {NoteManagerConstants} */
        this.constants = new NoteManagerConstants(this);

        /** @type {NoteManagerState} */
        this.state = new NoteManagerState(this);

        /** @type {NoteManagerCache} */
        this.cached = new NoteManagerCache(this);

        /** @type {NoteManagerAPI} */
        this.api = new NoteManagerAPI(this);

        /** Modals */
        this.modals = {
            add: useModalToggle().modal,
            edit: useModalToggle().modal,
            delete: useModalToggle().modal,
        };

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {NoteManager['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {NoteManager['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {NoteManager['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {NoteManager['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<NoteManagerConstants, 'initialize'> & Omit<NoteManagerState, 'initialize'> & Omit<NoteManagerCache, 'initialize' | 'initStatusConditionals' | 'initOpenModalConditionals' | 'initNoteTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     * @returns {NoteManagerAPI['events'] & NoteManagerAPI['methods']}
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link NoteManager} composable.
 */
class NoteManagerConstants {
    /**
     * Instantiate submodule.
     * @param {NoteManager} context
     */
    constructor(context) {
        /** @type {NoteManager} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ([
            'add',
            'edit',
            'view',
            'delete',
        ]);
        /** @type {INoteTemplate} */
        this.DefaultNoteTarget = {
            id: null,
            title: '',
            author: '',
            content: '',
            dateStart: DateTimeLocal.format(Date.now()),
            dateEnd: '',
            hierarchy: [],
            hierarchyId: null,
            locations: [],
            locationsCount: 0,
            visible: true,
        };
        /** Default column definition. */
        this.defaultColDef = Object.freeze({
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            headerName: '',
            cellClass: 'leading-5 py-2 break-normal',
            flex: 1,
        });
        /** Get column fieldnames as a constant enum. */
        this.colFields = Enum.create({
            id: 'id',
            idDataAnalyst: 'id_da',
            title: 'title',
            content: 'content',
            author: 'author',
            dateStart: 'dateStart',
            dateEnd: 'dateEnd',
            path: 'path',
        });
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link NoteManager} composable.
 */
class NoteManagerState {
    /**
     * Instantiate submodule.
     * @param {NoteManager} context
     */
    constructor(context) {
        /** @type {NoteManager} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {V.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        /** Is the view/edit modal in editing mode? */
        this.editing = ref(false);

        // ==== ACCOUNT INDEX ====
        /** @type {V.Ref<Map<Number, NoteResource>>} */
        this.noteIndex = ref(new Map());

        // ==== ACCOUNT TARGETS ====
        /** @type {V.Ref<INoteTemplate>} Note template. */
        this.noteToAdd = ref(null);

        /** @type {V.Ref<IEditNoteTarget>} Note target. */
        this.noteToEdit = ref(null);

        /** @type {V.Ref<IDeleteNoteTarget>} Note target. */
        this.noteToDelete = ref(null);

        // ==== AG GRID ====
        /** @type {V.Ref<Array<NoteResource>>} */
        this.rowData = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefsDA = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link NoteManager} composable.
 */
class NoteManagerCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {NoteManager} context
     */
    constructor(context) {
        /** @type {NoteManager} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
        // ==== CONDITIONALS (MODALS) ====
        this.initOpenModalConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state, modals } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditing = computed(() => {
            return state.editing.value === true;
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.notes.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }

    /**
     * Initialize the open modal conditionals.
     */
    initOpenModalConditionals() {
        const { state, modals } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isAddNoteModalOpen = modals.add.isOpen;

        /** @type {V.ComputedRef<Boolean>} */
        this.isViewNoteModalOpen = computed(
            () => modals.edit.isOpen.value && state.editing.value !== true
        );

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditNoteModalOpen = computed(
            () => modals.edit.isOpen.value && state.editing.value === true
        );

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmDeleteModalOpen = modals.delete.isOpen;
    }
}

class NoteManagerAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {NoteManager} context
     */
    constructor(context) {
        /** @type {NoteManager} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Format the ISO string into just its date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateComponentFormat = (params) => {
            const value = params.value;
            if (!isNil(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatted = formatISO(date, {
                    format: 'extended',
                    representation: 'date',
                });
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Use locale comparator for strings.
         * @type {AgGrid.ColumnDef['comparator']}
         */
        const useLocaleComparator = (valueA, valueB) => {
            const valueALower = valueA.toLowerCase().trim();
            const valueBLower = valueB.toLowerCase().trim();
            return valueALower.localeCompare(valueBLower, 'en');
        };

        /**
         * Get the account hierarchy labels.
         */
        const getAccountHierarchyLabels = () => {
            const defaultLabels = ['Site', 'Building', 'Floor', 'Room'];
            const accountLabels =
                $api.context.store.state.accounts?.account?.treeLabels ?? null;
            return accountLabels ?? defaultLabels;
        };

        /**
         * Get the note hierarchy path.
         * @type {AgGrid.ValueGetterFunc}
         */
        const usePathGetter = (params) => {
            const hasHierarchy = !!params?.data?.hierarchyId;
            const hasLocations = params?.data?.locationsCount > 0;

            // Get the labels.
            const labels = [...getAccountHierarchyLabels(), 'Location'];

            // Get hierarchy between 0 and 4 elements long.
            const hierarchy = hasHierarchy
                ? params?.data?.path?.split('/')
                : [];

            // If locations are present, add location name to end of hierarchy.
            if (hasLocations) {
                hierarchy.push(params?.data?.locations[0].name);
            }

            // Add null entry so we know when to terminate.
            hierarchy.push(null);

            // Map labels into their appropriate path segment.
            const segments = hierarchy.reduce((array, next, index) => {
                if (index >= labels.length) {
                    // Return current array, if max size is found.
                    return array;
                }
                if (next === null || next === '') {
                    // Append the `All ...` marker.
                    const label = labels[index];
                    return [...array, `All ${label}s`];
                }
                // If non-null, return the name.
                return [...array, String(next)];
            }, []);

            // Return the path.
            return !!segments && segments.length > 0
                ? segments.join('/')
                : null;
        };

        /**
         * Get reference to the current account.
         * @returns {Readonly<Pick<AccountResource, 'id'>>}
         */
        const getCurrentAccount = () =>
            Object.freeze(
                pick(this.context.store.state.accounts.account, 'id')
            );

        /**
         * Get reference to the current user.
         * @returns {String}
         */
        const getCurrentAuthor = () =>
            this.context.store.state?.users?.me?.username;

        /**
         * Get the default column definitions.
         * @returns {Readonly<AgGrid.ColumnDef>}
         */
        const getDefaultColDef = () => this.context.constants.defaultColDef;

        /**
         * Get keyed column definitions.
         * @returns {Readonly<Partial<Record<keyof fields, { field: fields[keyof fields] } & AgGrid.ColumnDef>>>}
         */
        const getColumnSchema = () => {
            const fields = this.context.constants.colFields;
            return {
                id: {
                    headerName: '',
                    field: fields.id,
                    cellRenderer: NoteManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    minWidth: 115,
                    maxWidth: 145,
                    cellRendererParams: {
                        /**
                         * Handle viewing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleView: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.viewNote(note);
                        },
                        /**
                         * Handle editing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.editNote(note);
                        },
                        /**
                         * Handle deletion of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.deleteNote(note);
                        },
                        /**
                         * Handle editing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleAnalysis: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.context.router.push(`/analysis?note=${id}`);
                        },
                    },
                },
                idDataAnalyst: {
                    headerName: '',
                    field: fields.id,
                    cellRenderer: NoteManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    minWidth: 50,
                    maxWidth: 70,
                    cellRendererParams: {
                        /**
                         * Handle viewing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleView: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.viewNote(note);
                        },
                        /**
                         * Handle editing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.editNote(note);
                        },
                        /**
                         * Handle deletion of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.events.onClick.deleteNote(note);
                        },
                        /**
                         * Handle editing of a note.
                         * @param {Object} event
                         * @param {Number} index Note index.
                         */
                        handleAnalysis: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const note = state.noteIndex.value.get(id);
                            $api.context.router.push(`/analysis?note=${id}`);
                        },
                    },
                },
                title: {
                    headerName: 'Note Title',
                    field: fields.title,
                    flex: 1.5,
                    minWidth: 75,
                    wrapText: true,
                    autoHeight: true,
                    sort: 'asc',
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const title = params?.data?.title;
                        return !!title && title !== '' ? title : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No title provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.title,
                    },
                },
                author: {
                    headerName: 'Author',
                    field: fields.author,
                    flex: 1.15,
                    minWidth: 100,
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const author = !!params.data
                            ? params?.data?.author
                            : null;
                        const username =
                            !!author && !!author?.id ? author?.username : null;
                        return !!username ? username : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No author provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.author?.id,
                    },
                },
                content: {
                    headerName: 'Note Content',
                    field: fields.content,
                    flex: 3,
                    maxWidth: 200,
                    comparator: useLocaleComparator,
                    valueGetter: (params) => {
                        const content = params?.data?.content;
                        return !!content && content !== '' ? content : null;
                    },
                    cellRenderer: (params) =>
                        params?.value ?? 'No content provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.content,
                    },
                },
                dateStart: {
                    headerName: 'Start Date',
                    field: fields.dateStart,
                    flex: 1.25,
                    filter: false,
                    minWidth: 100,
                    valueFormatter: useDateComponentFormat,
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.dateStart,
                    },
                },
                dateEnd: {
                    headerName: 'End Date',
                    field: fields.dateEnd,
                    filter: false,
                    maxWidth: 100,
                    valueFormatter: useDateComponentFormat,
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.dateEnd,
                    },
                },
                path: {
                    headerName: 'Association',
                    field: fields.path,
                    flex: 3,
                    minWidth: 200,
                    wrapText: true,
                    autoHeight: true,
                    valueGetter: usePathGetter,
                    cellRenderer: (params) => {
                        const path = params.value;
                        const formatted = path.split('/').join(' / ');
                        return !!path ? formatted : 'No association provided';
                    },
                    cellClassRules: {
                        'text-gray-400': (params) => {
                            const hasHierarchy = params?.data?.hierarchyId;
                            const hasLocations =
                                params?.data?.locationsCount &&
                                params?.data?.locationsCount > 0;
                            return !hasHierarchy && !hasLocations;
                        },
                    },
                },
            };
        };

        /**
         * Get column definitions in ordered array.
         * @returns {Readonly<Array<schema[keyof schema]>>}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return Object.freeze([
                schema.id,
                schema.title,
                schema.path,
                schema.author,
                schema.dateStart,
            ]);
        };
        /**
         * Get column definitions in ordered array.
         * @returns {Readonly<Array<schema[keyof schema]>>}
         */
        const getDataAnalystColumnDefs = () => {
            const schema = getColumnSchema();
            return Object.freeze([
                schema.idDataAnalyst,
                schema.title,
                schema.path,
                schema.author,
                schema.dateStart,
            ]);
        };

        /**
         * Create note index from array,
         * @param {NoteResource[]} notes
         * @returns {Map<Number, NoteResource>}
         */
        const getNotesAsIndex = (notes) => {
            /** @type {[ id: Number, note: NoteResource ][]} */
            const entries = notes.map((a) => {
                /** @type {[ id: Number, note: NoteResource ]} */
                const entry = [a.id, clone(a)];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Create row data from array of notes.
         * @param {Readonly<NoteResource[]>} notes
         * @returns {NoteResource[]}
         */
        const getNotesAsRowData = (notes) => {
            return notes.map((note) => getNoteAsRecord(note));
        };

        /**
         * Clone the note resource.
         * @param {Readonly<NoteResource>} note
         * @returns {NoteResource}
         */
        const getNoteAsRecord = (note) => {
            const instance = { ...note };
            instance.locations = clone(note?.locations ?? []);
            instance.hierarchy = clone(note?.hierarchy ?? []);
            instance.path = note?.path ?? '';
            return instance;
        };

        /**
         * Copy note from index as a selected note target.
         * @param {NoteResource['id']} id
         * @returns {IEditNoteTarget}
         */
        const getNoteAsEditTarget = (id) => {
            const source = state.noteIndex.value.get(id);
            const target = clone(
                pick(
                    source,
                    'id',
                    'title',
                    'author',
                    'content',
                    'dateStart',
                    'dateEnd',
                    'hierarchy',
                    'hierarchyId',
                    'locations',
                    'locationsCount',
                    'visible'
                )
            );
            // @ts-ignore
            target.author = target?.author?.username;
            return target;
        };

        /**
         * Copy note from index as a selected note target.
         * @param {NoteResource['id']} id
         * @returns {IDeleteNoteTarget}
         */
        const getNoteAsDeleteTarget = (id) => {
            const source = state.noteIndex.value.get(id);
            const target = clone(pick(source, 'id', 'title'));
            return target;
        };

        /**
         * Sanitize the note resource as a valid request.
         * @param {ICreateNoteRequest} source
         * @return {Readonly<ICreateNoteRequest>}
         */
        const getSanitizedCreateRequest = (source) => {
            /** @type {Writeable<ICreateNoteRequest>} */
            const request = Object.assign(
                clone(this.context.constants.DefaultNoteTarget),
                source
            );

            // Sanitize the date field.
            console.warn('TODO: Sanitize title.');
            console.warn('TODO: Sanitize content.');
            console.warn('TODO: Sanitize dateStart.');
            console.warn('TODO: Sanitize dateEnd.');
            console.warn('TODO: Sanitize hierarchyId.');
            console.warn('TODO: Sanitize locations.');

            // Send back the sanitized request.
            return Object.freeze(request);
        };

        /**
         * Sanitize the note resource as a valid request.
         * @param {IEditNoteRequest} source
         * @return {Readonly<IEditNoteRequest>}
         */
        const getSanitizedEditRequest = (source) => {
            /** @template [T=any] @typedef {{ -readonly [P in keyof T]: T[P] }} Writeable */
            /** @type {Writeable<IEditNoteRequest>} */
            const request = Object.assign(
                clone(this.context.constants.DefaultNoteTarget),
                source
            );

            // Sanitize the date field.
            console.warn('TODO: Sanitize title.');
            console.warn('TODO: Sanitize content.');
            console.warn('TODO: Sanitize dateStart.');
            console.warn('TODO: Sanitize dateEnd.');
            console.warn('TODO: Sanitize hierarchyId.');
            console.warn('TODO: Sanitize locations.');

            // Send back the sanitized request.
            return Object.freeze(request);
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            useDateComponentFormat,
            getCurrentAccount,
            getCurrentAuthor,
            getDefaultColDef,
            getColumnSchema,
            getColumnDefs,
            getDataAnalystColumnDefs,
            getNotesAsIndex,
            getNotesAsRowData,
            getNoteAsRecord,
            getNoteAsEditTarget,
            getNoteAsDeleteTarget,
            getSanitizedCreateRequest,
            getSanitizedEditRequest,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /** Set the editing status. */
        const setEditing = (mode = true) => {
            state.editing.value = mode === true;
        };

        /**
         * Set note index instance.
         * @param {Map<Number, NoteResource>} index
         */
        const setNoteIndex = (index) => {
            state.noteIndex.value = new Map(index.entries());
        };

        /**
         * Set note target for new note.
         * @param {Readonly<INoteTemplate>} template
         */
        const setAddNoteTemplate = (template) => {
            if (!isNil(template)) {
                // If target is defined, clone the default note target to get placeholders.
                const clean = this.context.constants.DefaultNoteTarget;
                const instance = Object.assign({}, clean, template);
                instance.author = $api.getters.getCurrentAuthor();
                state.noteToAdd.value = instance;
            } else {
                // Otherwise, set the null value.
                state.noteToAdd.value = null;
            }
        };

        /**
         * Set note target for the corresponding id.
         * @param {NoteResource['id']} id
         */
        const setEditNoteTarget = (id) => {
            if (!isNil(id)) {
                // If id is defined, find the matching target (if possible).
                const { getNoteAsEditTarget } = $api.getters;
                const target = id ? getNoteAsEditTarget(id) : null;
                state.noteToEdit.value = target;
            } else {
                // Otherwise, set the null value.
                state.noteToEdit.value = null;
            }
        };

        /**
         * Set note target for the corresponding id.
         * @param {NoteResource['id']} id
         */
        const setDeleteNoteTarget = (id) => {
            if (!isNil(id)) {
                // If id is defined, find the matching target (if possible).
                const { getNoteAsDeleteTarget } = $api.getters;
                const target = id ? getNoteAsDeleteTarget(id) : null;
                state.noteToDelete.value = target;
            } else {
                // Otherwise, set the null value.
                state.noteToDelete.value = null;
            }
        };

        /**
         * Set the row data.
         * @param {NoteResource[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setLoading,
            setEditing,
            setNoteIndex,
            setRowData,
            get setNoteTarget() {
                return {
                    toAdd: setAddNoteTemplate,
                    toView: setEditNoteTarget,
                    toEdit: setEditNoteTarget,
                    toDelete: setDeleteNoteTarget,
                };
            },
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When notes index is loaded/refreshed,
         * update the row data
         * @param {NoteResource[]} notes
         */
        const onUpdateNotes = (notes) => {
            const { getNotesAsIndex, getNotesAsRowData } = $api.getters;
            const { setNoteIndex, setRowData } = $api.setters;
            const noteIndex = getNotesAsIndex(notes);
            const noteData = getNotesAsRowData(notes);
            setNoteIndex(noteIndex);
            setRowData(noteData);
        };

        /**
         * On toggle editing.
         * @param {IEditNoteTarget} target
         */
        const onChangeEditing = (target) => {
            if ($api.context.cached.isEditing.value !== true) {
                onCancelViewNote();
                onClickEditNote(target);
            } else {
                onCancelEditNote();
                onClickViewNote(target);
            }
        };

        /**
         * On click add note.
         */
        const onClickAddNote = () => {
            const { open } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            setNoteTarget.toAdd({
                id: null,
                title: '',
                content: '',
                dateStart: DateTimeLocal.format(Date.now()),
            });
            setEditing(true);
            open.addNoteModal();
        };

        /**
         * On click view note.
         * @param {IEditNoteTarget} target
         */
        const onClickViewNote = (target) => {
            const { open, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            setNoteTarget.toView(target.id);
            changeRoute(target.id);
            setEditing(false);
            open.viewNoteModal();
        };

        /**
         * On click edit note.
         * @param {IEditNoteTarget} target
         */
        const onClickEditNote = (target) => {
            const { open, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            setNoteTarget.toEdit(target.id);
            changeRoute(target.id);
            setEditing(true);
            open.editNoteModal();
        };

        /**
         * On click delete note.
         * @param {IDeleteNoteTarget} target
         */
        const onClickDeleteNote = (target) => {
            const { open } = $api.methods;
            const { setNoteTarget } = $api.setters;
            setNoteTarget.toDelete(target.id);
            open.confirmDeleteModal();
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelAddNote = () => {
            const { close, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            close.addNoteModal();
            setNoteTarget.toAdd(null);
            setEditing(false);
            changeRoute(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelViewNote = () => {
            const { close, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            close.viewNoteModal();
            setEditing(false);
            setNoteTarget.toView(null);
            changeRoute(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelEditNote = () => {
            const { close, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            close.editNoteModal();
            setEditing(false);
            setNoteTarget.toEdit(null);
            changeRoute(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelDeleteNote = () => {
            const { close, changeRoute } = $api.methods;
            const { setNoteTarget, setEditing } = $api.setters;
            close.confirmDeleteModal();
            setEditing(false);
            setNoteTarget.toDelete(null);
            changeRoute(null);
        };

        /**
         * Submit the action.
         * @param {Omit<INoteTarget, 'id'>} note
         */
        const onSubmitAddNote = async (note) => {
            const { close, changeRoute, refreshNotes } = $api.methods;
            const { getCurrentAccount, getSanitizedCreateRequest } =
                $api.getters;
            const { setLoading, setNoteTarget, setEditing } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.addNoteModal();

            // Stop editing.
            setEditing(false);

            // Clear alerts, if present.
            clearAlert('add-success');
            clearAlert('add-errors');
            clearAlert('add-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[note::add]`);
                setLoading('loading');

                /** Current account. */
                const account = getCurrentAccount();

                /** @type {ICreateNoteRequest} */
                const body = {
                    title: note.title,
                    content: note.content,
                    date: note.dateStart,
                    end_date: note.dateEnd,
                    location_hierarchy_id: note.hierarchyId,
                    locations: note.locations.map((loc) => loc.id),
                };

                /** @type {ICreateNoteRequest} */
                const request = getSanitizedCreateRequest(body);

                /** @type {IResponseResult} */
                const result = await createNote(account, request);

                // If successful, refresh notes.
                if (result.status === 200) {
                    // Refresh the notes index, if successful. (Otherwise, nothing to reload).
                    await refreshNotes(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `add-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while adding an note`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `add-error`,
                        type: 'error',
                        title: `An unknown error occurred while adding an note`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[note::add]`);
                // Clear the target.
                setNoteTarget.toAdd(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
                // Go back to note manager index.
                changeRoute(null);
            }
        };

        /**
         * Submit the action.
         * @param {INoteResource} note
         */
        const onSubmitEditNote = async (note) => {
            const { close, changeRoute, refreshNotes } = $api.methods;
            const { getCurrentAccount, getSanitizedEditRequest } = $api.getters;
            const { setLoading, setNoteTarget, setEditing } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.editNoteModal();

            // Stop editing.
            setEditing(false);

            // Clear alerts, if present.
            clearAlert('edit-success');
            clearAlert('edit-errors');
            clearAlert('edit-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[note::edit]`);
                setLoading('loading');

                /** Current account. */
                const account = getCurrentAccount();

                /** @type {IEditNoteTarget} */
                const target = pick(state.noteToEdit.value, 'id');

                /** @type {IEditNoteRequest} */
                const body = {
                    title: note.title,
                    content: note.content,
                    date: note.dateStart,
                    end_date: note.dateEnd,
                    location_hierarchy_id: note.hierarchyId,
                    locations: note.locations.map((loc) => loc.id),
                };

                /** @type {IEditNoteRequest} */
                const request = getSanitizedEditRequest(body);

                /** @type {IResponseResult} */
                const result = await updateNoteById(account, target, request);

                // If successful, refresh notes.
                if (result.status === 200) {
                    // Refresh the notes index, if successful. (Otherwise, nothing to reload).
                    await refreshNotes(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `edit-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while updating the note`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `edit-error`,
                        type: 'error',
                        title: `An unknown error occurred while editing an note`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[note::edit]`);
                // Clear the target.
                setNoteTarget.toEdit(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
                // Go back to note manager index.
                changeRoute(null);
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitDeleteNote = async () => {
            const { close, changeRoute, refreshNotes } = $api.methods;
            const { getCurrentAccount } = $api.getters;
            const { setLoading, setNoteTarget, setEditing } = $api.setters;
            const { pushAlert, createAlert, clearAlert } = alerts.methods;

            // Close the modal.
            close.confirmDeleteModal();

            // Stop editing.
            setEditing(false);

            // Clear alerts, if present.
            clearAlert('delete-success');
            clearAlert('delete-errors');
            clearAlert('delete-error');

            // Prepare alert notifications.
            /** @type {Array<import('@/components/alerts/hooks/useAlerts').AlertDef>} */
            const notifications = [];

            try {
                console.time(`[note::delete]`);
                setLoading('loading');

                /** Current account. */
                const account = getCurrentAccount();

                /** @type {IDeleteNoteTarget} */
                const target = {
                    id: state.noteToDelete.value.id,
                    title: state.noteToDelete.value.title,
                };

                /** @type {IResponseResult} */
                const result = await deleteNoteById(account, target);

                // If successful...
                if (result.status === 200) {
                    // Refresh the notes index, if successful. (Otherwise, nothing to reload).
                    await refreshNotes(true);
                    // Notify successful response.
                    setLoading('success');
                } else {
                    // Notify failed response.
                    setLoading('failure');
                }

                // Create notification messages.
                if (result.messages?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-success`,
                            type: 'success',
                            title: result.label,
                            messages: result.messages,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.warnings?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-warnings`,
                            type: 'warning',
                            title: result.label,
                            messages: result.warnings,
                            dismissable: true,
                        })
                    );
                }
                // Create error messages.
                if (result.errors?.length > 0) {
                    notifications.push(
                        createAlert({
                            id: `delete-errors`,
                            type: 'error',
                            title: `One or more error(s) occurred while deleting the note`,
                            messages: result.errors,
                            dismissable: true,
                        })
                    );
                }
            } catch (error) {
                // Set failure status.
                setLoading('failure');

                // Add fallback error notification.
                notifications.push(
                    createAlert({
                        id: `delete-error`,
                        type: 'error',
                        title: `An unknown error occurred while deleting the note`,
                        messages: [
                            error?.message,
                            'Please contact your system administrator.',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                // Flag the end of the event.
                console.timeEnd(`[note::delete]`);
                // Clear the target.
                setNoteTarget.toDelete(null);
                // Send notifications, if more than zero are present.
                notifications.forEach((alert) => {
                    pushAlert(alert);
                });
                // Go back to note manager index.
                changeRoute(null);
            }
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getColumnDefs } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
            const { getDataAnalystColumnDefs } = $api.getters;
            state.colDefsDA.value = [...getDataAnalystColumnDefs()];

            // Close all modals, if open.
            const { closeModalIfOpen } = $api.methods;
            closeModalIfOpen('add');
            closeModalIfOpen('edit');
            closeModalIfOpen('delete');

            // Stop editing.
            const { setNoteTarget, setEditing } = $api.setters;
            setEditing(false);
            setNoteTarget.toAdd(null);
            setNoteTarget.toEdit(null);
            setNoteTarget.toDelete(null);
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateNotes,
            get onChange() {
                return {
                    editing: onChangeEditing,
                };
            },
            get onClick() {
                return {
                    addNote: onClickAddNote,
                    viewNote: onClickViewNote,
                    editNote: onClickEditNote,
                    deleteNote: onClickDeleteNote,
                };
            },
            get onCancel() {
                return {
                    addNote: onCancelAddNote,
                    viewNote: onCancelViewNote,
                    editNote: onCancelEditNote,
                    deleteNote: onCancelDeleteNote,
                };
            },
            get onSubmit() {
                return {
                    addNote: onSubmitAddNote,
                    viewNote: async () => true, // no-op when viewing notes.
                    editNote: onSubmitEditNote,
                    deleteNote: onSubmitDeleteNote,
                };
            },
        };
    }

    initMethods() {
        const $api = this;
        const { notes } = $api.context;

        /**
         * Update the row data after requesting notes from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshNotes = async (forceReload = false) => {
            const { setLoading } = $api.setters;
            try {
                console.time(`[notes::index] - Refreshing Note index:`);
                setLoading('loading');
                // ==== REFRESH ====
                const noteList = await notes.refreshNoteIndex(true);
                $api.events.onUpdateNotes(noteList ?? []);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            } finally {
                console.timeEnd(`[notes::index] - Refreshing Note index:`);
            }
        };

        /** Open modal. */
        const openAddNoteModal = $api.context.modals.add.open;

        /** Open modal. */
        const openViewNoteModal = $api.context.modals.edit.open;

        /** Open modal. */
        const openEditNoteModal = $api.context.modals.edit.open;

        /** Open modal. */
        const openConfirmDeleteModal = $api.context.modals.delete.open;

        /** Close modal. */
        const closeAddNoteModal = $api.context.modals.add.close;

        /** Close modal. */
        const closeViewNoteModal = $api.context.modals.edit.close;

        /** Close modal. */
        const closeEditNoteModal = $api.context.modals.edit.close;

        /** Close modal. */
        const closeConfirmDeleteModal = $api.context.modals.delete.close;

        /**
         * Open modal if it matches.
         * @param {'add' | 'view' | 'edit' | 'delete'} id
         */
        const openModalIfClosed = async (id) => {
            try {
                const key = id === 'view' ? 'edit' : id;
                await $api.context.modals[key].open();
            } catch (e) {
                console.warn(e);
            }
        };

        /**
         * Close modal if it matches.
         * @param {'add' | 'view' | 'edit' | 'delete'} id
         */
        const closeModalIfOpen = async (id) => {
            try {
                const key = id === 'view' ? 'edit' : id;
                await $api.context.modals[key].close();
            } catch (e) {
                console.warn(e);
            }
        };

        /**
         * Format the date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatDate = (params) => {
            /** datetime value in the format of 'yyyy-MM-DDThh:mm:ss.sssZ' */
            const datetime = /** @type {String} */ (params.value);
            if (!isNil(datetime) && datetime !== '') {
                const [date, time] = datetime.split('T');
                return date;
            }
            return 'No date provided.';
        };

        /**
         * Change the route when dealing with a note.
         * @param {Number} id
         */
        const changeRoute = (id) => {
            if (!!id) {
                $api.context?.router?.push(`/note-manager/notes?note=${id}`);
            } else if (
                !['/notes', '/note-manger/notes'].includes(
                    $api.context?.router?.currentRoute.value.fullPath
                )
            ) {
                $api.context?.router?.push(`/note-manager/notes`);
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            changeRoute,
            refreshNotes,
            formatDate,
            openModalIfClosed,
            closeModalIfOpen,
            get open() {
                return {
                    addNoteModal: openAddNoteModal,
                    viewNoteModal: openViewNoteModal,
                    editNoteModal: openEditNoteModal,
                    confirmDeleteModal: openConfirmDeleteModal,
                };
            },
            get close() {
                return {
                    addNoteModal: closeAddNoteModal,
                    viewNoteModal: closeViewNoteModal,
                    editNoteModal: closeEditNoteModal,
                    confirmDeleteModal: closeConfirmDeleteModal,
                };
            },
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 */
export const useNoteManager = () => {
    const context = new NoteManager();
    return context.initialize();
};

// <!-- DEFAULT -->
export default useNoteManager;
