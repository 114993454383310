<template>
    <div
        v-if="!!sourceNote"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyNote"
            :actions="false"
            :config="config"
            #default="context"
            preserve
        >
            <!-- NOTE DETAILS -->
            <FormSection
                class="pt-4"
                title="Note Details"
                :grid="[
                    'mt-6',
                    'grid',
                    'grid-cols-1',
                    'gap-x-0',
                    'gap-y-2',
                    'sm:grid-cols-4',
                ]"
            >
                <!-- TITLE (2/3 cols) -->
                <FormKit
                    id="title"
                    type="text"
                    name="title"
                    label="* Title"
                    :classes="{
                        outer: isLoading
                            ? 'col-span-3 px-4 sm:px-2 animate-pulse'
                            : 'col-span-3 px-4 sm:px-2',
                        inner: isEditing
                            ? 'border-black hover:border-blue-600'
                            : 'mb-3 border-gray-300 border-dashed overflow-x-auto',
                        input: isEditing
                            ? 'border-none'
                            : '$reset w-full rounded-lg border-none px-3 overflow-x-auto cursor-not-allowed hover:text-gray-600',
                    }"
                    :placeholder="
                        isEditing ? 'Enter title here...' : 'No title provided.'
                    "
                    :disabled="!isEditing"
                    validation="required|length:1,"
                    valdiation-name="Note Title"
                />
                <!-- AUTHOR (1/3 cols) -->
                <FormKit
                    id="author"
                    type="text"
                    name="author"
                    label="Author"
                    :classes="{
                        outer: isLoading
                            ? 'col-span-1 px-4 sm:px-2 animate-pulse'
                            : 'col-span-1 px-4 sm:px-2',
                        inner: 'border-none',
                        input: '$reset w-full rounded-lg border-none px-3 cursor-not-allowed hover:text-gray-600',
                    }"
                    placeholder="No author provided."
                    :value="dirtyNote?.author"
                    :readonly="true"
                    :ignore="true"
                />
                <!-- CONTENT (3/3 cols) -->
                <FormKit
                    id="content"
                    type="textarea"
                    name="content"
                    label="Content"
                    :classes="{
                        outer: isLoading
                            ? 'col-span-4 px-4 sm:px-2 animate-pulse w-full'
                            : 'col-span-4 px-4 sm:px-2 w-full',
                        wrapper: 'w-full',
                        inner: isEditing ? 'w-full' : 'w-full border-none',
                        input: isEditing
                            ? '$reset mt-1 h-36 w-full rounded-lg border-black px-3 hover:border-blue-600'
                            : '$reset mt-1 h-36 w-full rounded-lg border-gray-300 border-dashed px-3 cursor-not-allowed hover:text-gray-600',
                    }"
                    :placeholder="
                        isEditing
                            ? 'Enter content here...'
                            : 'No content provided.'
                    "
                    :disabled="!isEditing"
                    validation="length:0,300"
                    validation-label="Content"
                />
                <!-- DATE START -->
                <FormKit
                    id="dateStart"
                    type="datetime-local"
                    name="dateStart"
                    label="* Start Date"
                    :classes="{
                        outer: isLoading
                            ? 'col-span-2 px-4 sm:px-2 animate-pulse'
                            : 'col-span-2 px-4 sm:px-2',
                        inner: isEditing ? '' : 'border-none',
                        input: isEditing
                            ? ''
                            : '$reset w-full rounded-lg border-none px-3 cursor-not-allowed hover:text-gray-600',
                    }"
                    :placeholder="
                        isEditing
                            ? 'Enter date here...'
                            : 'No start date provided.'
                    "
                    v-model="dirtyStartDateLocal"
                    @input="validateStartDateLocal"
                    :disabled="!isEditing"
                    :validation="[['required']]"
                    :validation-messages="{
                        required: 'A valid date is required.',
                    }"
                    validation-visibility="live"
                    validation-label="Date"
                    :ignore="true"
                />
                <!-- DATE END -->
                <FormKit
                    id="dateEnd"
                    type="datetime-local"
                    name="dateEnd"
                    label="End Date"
                    :classes="{
                        outer: isLoading
                            ? 'col-span-2 px-4 sm:px-2 animate-pulse'
                            : 'col-span-2 px-4 sm:px-2',
                        inner: isEditing ? '' : 'border-none',
                        input: isEditing
                            ? ''
                            : '$reset w-full rounded-lg border-none px-3 cursor-not-allowed hover:text-gray-600',
                    }"
                    :placeholder="
                        isEditing
                            ? 'Enter date here...'
                            : 'No end date provided.'
                    "
                    v-model="dirtyEndDateLocal"
                    @input="validateEndDateLocal"
                    :disabled="!isEditing"
                    :validation="[
                        ['required'],
                        ['date_after', dirtyNote.dateStart],
                        ['date_format', MM / DD / YYYY],
                    ]"
                    :validation-messages="{
                        date_after: 'Date must be after start date.',
                        required:
                            'An invalid or empty date will be set automatically.',
                    }"
                    validation-visibility="live"
                    validation-label="Date"
                    :ignore="true"
                />
            </FormSection>
            <!-- NOTE ASSOCIATION -->
            <FormSection
                class="pt-4"
                title="Location Assignment"
                :grid="[
                    'mt-6',
                    'grid',
                    'grid-cols-1',
                    'gap-x-0',
                    'gap-y-2',
                    'sm:grid-cols-6',
                ]"
            >
                <!-- HIERARCHY -->
                <NoteAssociationFields
                    :dirtyNote="isEditing ? dirtyNote : sourceNote"
                    :isEditing="isEditing"
                    :isLoading="isLoading"
                    :debug="debug"
                    @input="onHierarchyInput"
                />
            </FormSection>
            <!-- FORM ACTIONS -->
            <FormSection>
                <section>
                    <p class="text-gray-400 text-sm">
                        * indicates a required field
                    </p>
                </section>
                <section class="col-start-1">
                    <div class="pt-5 flex justify-start items-center">
                        <ModalButton
                            v-if="isEditing && sourceNote.id !== null"
                            theme="white"
                            label="Back"
                            @click="onClickSwitch"
                        />
                    </div>
                </section>
                <section class="col-start-3">
                    <div class="pt-5">
                        <div class="flex">
                            <div
                                class="flex justify-end items-center space-x-4 pl-10"
                            >
                                <ModalButton
                                    v-if="isDataAnalyst === false"
                                    theme="white"
                                    label="Cancel"
                                    @click="onClickCancel"
                                />
                                <ModalButton
                                    v-if="isEditing || sourceNote.id === null"
                                    theme="primary"
                                    label="Save"
                                    @click="onClickSave(context.state)"
                                    :disabled="isLoading"
                                />
                                <ModalButton
                                    v-else-if="
                                        sourceNote.id !== null &&
                                        isDataAnalyst === false
                                    "
                                    theme="primary"
                                    label="Edit"
                                    @click="onClickSwitch"
                                    :disabled="isEditing || isLoading"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </FormSection>
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        ref,
        reactive,
        onBeforeMount,
    } from 'vue';
    import { useStore } from 'vuex';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { startOfDay, endOfDay } from 'date-fns';

    // <!-- COMPONENTS -->
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import NoteAssociationFields from '~NoteManager/components/fields/NoteAssociationFields.vue';

    // <!-- TYPES -->
    import { NoteLocationResource } from '@/models/v1/notes/NoteLocation';
    import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';
    /** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

    /** @typedef {import('@/models/v1/notes/Note').NoteResource} NoteResource */
    /** @typedef {import('@/features/note-manager/hooks/useNoteManager').INoteTemplate} INoteTemplate */
    /** @typedef {import('@/features/note-manager/hooks/useNoteManager').INoteTarget} INoteTarget */

    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

    // <!-- COMPOSABLES -->
    import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'NoteFields',
        components: {
            ModalButton,
            FormSection,
            DebugFrame,
            NoteAssociationFields,
        },
        props: {
            sourceNote: {
                /** @type {Vue.PropType<INoteTemplate | INoteTarget>} */
                type: Object,
            },
            isEditing: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['switch', 'cancel', 'submit'],
        setup(props, context) {
            // ==== COMPOSE ====
            const store = useStore();

            // ==== STATE ====
            /** @type {Vue.Ref<INoteTemplate | INoteTarget>} */
            const dirtyNote = ref(null);
            /** @type {Vue.Ref<String>} */
            const dirtyStartDateLocal = ref(null);
            /** @type {Vue.Ref<String>} */
            const dirtyEndDateLocal = ref(null);

            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {Vue.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(
                () => user.value?.role === 'data-analyst'
            );

            const config = reactive({
                delay: 250,
                validationVisibility: 'blur',
            });

            // ==== EVENTS ====
            /**
             * Attempts to parse the iso string.
             * @param {String} isoDateString
             * @returns {String | null} `null` when parsing fails.
             */
            const getDateTimeLocalFromISOString = (isoDateString) => {
                try {
                    if (isoDateString?.length > 0) {
                        const date = DateTimeISO.parse(isoDateString);
                        const formatted = DateTimeLocal.format(date);
                        return formatted.substring(0, 16);
                    }
                    return null;
                } catch (e) {
                    console.warn(
                        `Cannot parse ISO date string ${isoDateString}.`
                    );
                    return null;
                }
            };

            /**
             * Attempts to parse the local string.
             * @param {String} localDateString
             * @returns {String | null} `null` when parsing fails.
             */
            const getISOStringFromDateTimeLocal = (localDateString) => {
                try {
                    if (localDateString?.length > 0) {
                        const date = DateTimeLocal.parse(localDateString);
                        const formatted = DateTimeISO.format(date);
                        return formatted.substring(0, 16);
                    }
                    return null;
                } catch (e) {
                    console.warn(
                        `Cannot parse local date string ${localDateString}.`
                    );
                    return null;
                }
            };

            /** Clone the clean note. */
            const resetDirtyNote = () => {
                const instance = { ...props.sourceNote };
                instance.locations = clone(props.sourceNote?.locations ?? []);
                instance.hierarchy = clone(props.sourceNote?.hierarchy ?? []);
                dirtyNote.value = instance;
                dirtyStartDateLocal.value =
                    getDateTimeLocalFromISOString(dirtyNote.value.dateStart) ??
                    DateTimeLocal.format(startOfDay(Date.now())).substring(
                        0,
                        16
                    );
                dirtyEndDateLocal.value =
                    getDateTimeLocalFromISOString(dirtyNote.value.dateEnd) ??
                    DateTimeLocal.format(
                        endOfDay(new Date(dirtyNote.value.dateStart))
                    ).substring(0, 16);
            };

            /**
             * Validate local date string.
             * @param {String} localDateString
             */
            const validateStartDateLocal = (localDateString) => {
                try {
                    const iso = getISOStringFromDateTimeLocal(localDateString);
                    if (!iso) {
                        throw new Error(
                            `Cannot parse local date string ${localDateString}.`
                        );
                    }
                    const instance = clone(dirtyNote.value);
                    instance.dateStart = iso;
                    dirtyNote.value = instance;
                } catch (e) {
                    console.warn(
                        `Cannot parse local date string ${localDateString}.`
                    );
                    const instance = clone(dirtyNote.value);
                    instance.dateStart = '';
                    dirtyNote.value = instance;
                }
            };

            /**
             * Validate local date string.
             * @param {String} localDateString
             */
            const validateEndDateLocal = (localDateString) => {
                try {
                    const iso = getISOStringFromDateTimeLocal(localDateString);
                    if (!iso) {
                        throw new Error(
                            `Cannot parse local date string ${localDateString}.`
                        );
                    }
                    const instance = clone(dirtyNote.value);
                    instance.dateEnd = iso;
                    dirtyNote.value = instance;
                } catch (e) {
                    console.warn(
                        `Cannot parse local date string ${localDateString}.`
                    );
                    const instance = clone(dirtyNote.value);
                    instance.dateEnd = '';
                    dirtyNote.value = instance;
                }
            };

            /** Save the dirty note. */
            const onClickSave = async (state) => {
                config.validationVisibility = 'live';
                if (state.valid) {
                    context.emit('submit', dirtyNote.value);
                } else {
                    console.error('Form is not valid.');
                }
            };

            /** Save the dirty note. */
            const onClickSwitch = async () => {
                resetDirtyNote();
                context.emit('switch', dirtyNote.value);
            };

            /** Clear the dirty note. */
            const onClickCancel = () => {
                resetDirtyNote();
                context.emit('cancel');
            };

            /**
             * Update the dirty note based on the location assignment.
             * @param {{ hierarchy?: LocationHierarchyResource[], hierarchyId?: Number, locations?: NoteLocationResource[] }} update
             */
            const onHierarchyInput = (update) => {
                const instance = clone(dirtyNote.value);
                instance.locations = update.locations?.map((l) => clone(l));
                instance.hierarchy = update.hierarchy?.map((h) => clone(h));
                instance.hierarchyId = update.hierarchyId;
                dirtyNote.value = instance;
            };

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source Note`, {
                        sourceNote: props.sourceNote,
                    }),
                    DebugObject.create(`Dirty Note`, {
                        dirtyNote: dirtyNote.value,
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // ==== LIFECYCLE ====
            onBeforeMount(() => {
                resetDirtyNote();
            });

            // ==== EXPOSE ====
            return {
                frame,
                dirtyNote,
                dirtyStartDateLocal,
                dirtyEndDateLocal,
                validateStartDateLocal,
                validateEndDateLocal,
                onClickSave,
                onClickSwitch,
                onClickCancel,
                onHierarchyInput,

                user,
                isDataAnalyst,
                config,
            };
        },
    });
</script>

<style lang="scss">
    .note-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
